<template>
  <div class="gps-box">
    <div class="gps-search">
      <div class="gps-item" v-for="(it, idx) in searchCols" :key="`a` + idx">
        <el-tree-select
          :ref="it.ref"
          v-if="it.type == 'tsel'"
          v-model="it.val"
          :data="it.data"
          :render-after-expand="false"
          show-checkbox
          :placeholder="it.title"
          :props="it.props"
          multiple
          collapse-tags
          collapse-tags-tooltip
          filterable
          clearable
          :default-expand-all="true"
        >
          <template #default="{ node, data }">
            <span class="prefix"
              ><img :src="data.icon" alt="" style="width: 17px; height: 17px"
            /></span>
            <span @dblclick="nodeClick(data)">{{ node.label }}</span>
          </template>
        </el-tree-select>
        <div v-if="it.type == 'car'" @click="open_tree(it.data)">
          <el-button class="xunze" round>
            {{ sel_cars_name }}
          </el-button>
        </div>
        <el-input
          v-if="it.type == 'ipt'"
          v-model="it.val"
          :placeholder="it.title"
          clearable
        ></el-input>
        <el-date-picker
          clearable
          v-if="it.type == 'datetime'"
          v-model="it.val"
          type="datetime"
          :placeholder="it.title"
          value-format="YYYY-MM-DD hh:mm:ss"
        />
        <el-date-picker
          clearable
          v-if="it.type == 'dtr'"
          v-model="it.val"
          type="datetimerange"
          range-separator="To"
          :start-placeholder="it.title_s"
          :end-placeholder="it.title_e"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
        <el-select
          clearable
          v-if="it.type == 'sel'"
          v-model="it.val"
          :placeholder="it.title"
          @change="it.change && it.change($event, callbackFun)"
        >
          <el-option
            v-for="item in it.data"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <el-select
          clearable
          v-if="it.type == 'csel'"
          v-model="it.val"
          :placeholder="it.title"
          @change="it.change && it.change({ evt: $event, it: it })"
        >
          <el-option
            v-for="item in it.data"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <el-select
          clearable
          v-if="it.type == 'msel'"
          multiple
          v-model="it.val"
          :placeholder="it.title"
        >
          <el-option
            v-for="item in it.data"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <el-button
          v-if="it.type == 'btn'"
          @click="it.click && it.click(callbackFun)"
          :type="it.btnType"
          :icon="it.icon"
          round
          >{{ it.title }}</el-button
        >
        <el-upload
          v-if="it.type == 'upload'"
          class="upload-demo"
          :action="it.url"
          name="excelFile"
          :data="uploadData"
          :limit="1"
          :on-success="handleUpload"
        >
          <el-button :type="it.btnType" :icon="it.icon" round>{{
            it.title
          }}</el-button>
        </el-upload>
      </div>
    </div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane :label="tabsLabel.tabA" name="list">
        <div class="gps-body cc1">
          <el-table :data="tableData" style="width: 100%" :height="tableHeight">
            <el-table-column
              :label="cls.name"
              :width="cls.width"
              v-for="cls in tableCols"
              :key="cls.filed"
            >
              <template #default="{ row }">
                <span
                  v-if="cls.render"
                  v-html="cls.render(row)"
                  @click="cls.click && cls.click(row, callbackFun)"
                >
                </span>
                <span
                  v-else
                  @click="cls.click && cls.click(row, callbackFun)"
                  >{{ row[cls.filed] }}</span
                >
              </template>
            </el-table-column>

            <el-table-column
              fixed="left"
              :label="$t('commKey.MINGXI')"
              width="90px"
              v-if="tableOps.length > 0"
            >
              <template #default="{ row }">
                <span class="gps-span" v-for="(op, idx) in tableOps" :key="idx">
                  <el-button
                    v-if="op.opType == 'btn'"
                    :icon="op.icon"
                    class="cc2"
                    :type="op.type"
                    @click="op.click && op.click(row, callbackFun)"
                    >{{ op.name }}</el-button
                  >
                  <el-popconfirm
                    :title="$t('commKey.CONFIRMTITLE')"
                    v-if="op.opType == 'del'"
                    @confirm="op.click && op.click(row, callbackFun)"
                  >
                    <template #reference>
                      <el-button :icon="op.icon" round :type="op.type">{{
                        op.name
                      }}</el-button>
                    </template>
                  </el-popconfirm>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="gps-page">
          <el-pagination
            :currentPage="query.page"
            :page-size="query.rp"
            :page-sizes="[20, 50, 100, 200, 300, 400]"
            :disabled="disabled"
            :background="background"
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="tabsLabel.tabB" name="detail">
        <div class="gps-body cc3">
          <el-table
            :data="tableDetailData"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              :label="cls.name"
              :width="cls.width"
              v-for="cls in tableDetailCols"
              :key="cls.filed"
            >
              <template #default="{ row }">
                <span
                  v-if="cls.render"
                  v-html="cls.render(row)"
                  @click="cls.click && cls.click(row, callbackFun)"
                >
                </span>
                <span
                  v-else
                  @click="cls.click && cls.click(row, callbackFun)"
                  >{{ row[cls.filed] }}</span
                >
                <!-- <span>{{ row[cls.filed] }}</span> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="gps-page">
          <el-pagination
            :currentPage="queryDetail.page"
            :page-size="queryDetail.rp"
            :page-sizes="[20, 50, 100, 200, 300, 400]"
            :disabled="disabledDetail"
            :background="backgroundDetail"
            layout="total, prev, pager, next"
            :total="totalDetail"
            @size-change="handleSizeChangeDetail"
            @current-change="handleCurrentChangeDetail"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-drawer
      v-model="drawer"
      :title="$t('map.item_info')"
      :direction="direction"
      :before-close="handleClose"
    >
      <el-input
        clearable
        v-model="query_key"
        :placeholder="$t('map.qingshuruchaxunguanjianzi')"
        @input="onQueryChanged"
      />
      <el-tree-v2
        highlight-current
        ref="treeRef_report"
        :data="treeDataMain"
        :height="treeH"
        :props="propoption"
        show-checkbox
        node-key="gps_id"
        @check-change="checkChange"
        :default-expanded-keys="defaultExpandedKeys"
        :filter-method="filterMethod"
      >
        <template #default="{ node, data }">
          <span class="prefix"
            ><img :src="data.icon" alt="" style="width: 17px; height: 17px"
          /></span>
          <span style="color: #333">{{ node.label }}</span>
        </template>
      </el-tree-v2>
    </el-drawer>
  </div>
</template>

<script setup>
import {
  ref,
  toRefs,
  defineExpose,
  reactive,
  getCurrentInstance,
  watch,
  computed,
  defineEmits,
  toRaw,
  onMounted,
} from "vue";
import { useI18n } from "vue-i18n";
import signObj from "../../../utils/upload-sign";
const { proxy } = getCurrentInstance();
// const { $api } = proxy.appContext.config.globalProperties;
const { t } = useI18n();
const props = defineProps({
  // defaultExpandedKeys: {
  //   type: Array,
  //   default: () => {},
  // },
  tabsLabel: {
    type: Object,
    default: () => {},
  },
  searchCols: {
    type: Array,
    default: () => {},
  },
  tableData: {
    type: Array,
    default: () => {},
  },
  tableCols: {
    type: Array,
    default: () => {},
  },
  tableOps: {
    type: Array,
    default: () => {},
  },
  query: {
    type: Object,
    default: () => {},
  },
  total: {
    type: Number,
    default: 0,
  },
  tableDetailCols: {
    type: Array,
    default: () => {},
  },
  queryDetail: {
    type: Object,
    default: () => {},
  },
  tableDetailData: {
    type: Array,
    default: () => {},
  },
  totalDetail: {
    type: Number,
    default: 0,
  },
  tabsName: {
    type: String,
    default: "list",
  },
});

const background = ref(false);
const disabled = ref(false);
const disabledDetail = ref(false);
const backgroundDetail = ref(false);

const treeRef_report = ref();
const defaultExpandedKeys = ref([]);

const query_key = ref("");

const treeH = 500;
const propoption = {
  icon: "icon",
  value: "gps_id",
  label: "nodeName",
  children: "children",
  disabled: "disabled",
};
const treeDataMain = ref([]);

const drawer = ref(false);
const direction = ref("ltr");
const sel_cars = ref([]);
const sel_cars_name = ref(t("map.qingxuanzecheliang"));

const emit = defineEmits([
  "callbackEvent",
  "handleCurrentChange",
  "handleCurrentChangeDetail",
  "tabsActiveNameChange",
]);

const filterMethod = (query, node) => {
  return node.nodeName.includes(query);
};
const onQueryChanged = (query) => {
  // this.$refs.treeRef.filter(query);
  treeRef_report.value.filter(query);
};
const checkChange = () => {
  // console.log(treeRef_report.value.getCheckedNodes());
  let ay = [];
  if (treeRef_report.value.getCheckedNodes()) {
    treeRef_report.value.getCheckedNodes().forEach((it) => {
      if (it.nodetype == 2) {
        ay.push(it);
      }
    });
  }
  sel_cars.value = ay;

  sel_cars_name.value =
    ay.length > 0
      ? t("map.gongxuanze") + ay.length
      : t("map.qingxuanzecheliang");
};

const open_tree = (data) => {
  console.log("open", data[0].gps_id);
  treeDataMain.value = data;

  defaultExpandedKeys.value = [data[0].gps_id];
  // gps_id

  drawer.value = true;
};
const handleClose = () => {
  drawer.value = false;
};

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  emit("handleCurrentChange", val);
};

const handleSizeChangeDetail = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChangeDetail = (val) => {
  emit("handleCurrentChangeDetail", val);
};

const callbackFun = (obj) => {
  let attr_search = toRaw(props.searchCols);
  let attr = {};
  attr_search.forEach((it) => {
    if (it.type != "btn") {
      switch (it.type) {
        case "dtr":
        case "msel":
          if (it.key) attr[it.key] = it.val.join(",");
          break;
        case "tsel":
          if (it.key) {
            // let data_map = new Map();
            // it.data.forEach((it_it) => {
            //   data_map.set(it_it.gps_id, it_it);
            // });
            // // console.log(data_map);
            // let data_out = [];
            // it.val.forEach((it_k) => {
            //   if (data_map.has(it_k)) {
            //     data_out.push(data_map.get(it_k));
            //   }
            // });
            attr[it.key] = toRaw(proxy.$refs[it.ref][0].getCheckedNodes());
          }
          // if (it.key) attr[it.key] = it.val;
          break;
        case "car":
          attr[it.key] = toRaw(sel_cars.value);
          break;

        default:
          if (it.key) attr[it.key] = it.val;
          break;
      }
    }
  });

  obj.search = attr;

  emit("callbackEvent", obj);
};

const uploadData = reactive(signObj);
const handleUpload = (response, uploadFile, uploadFiles) => {
  emit("callbackEvent", Object.assign({ type: "upload" }, response));
};

const get_heigth = () => {
  let h =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return parseFloat(h) - 242;
};
const tableHeight = ref(300);
onMounted(() => {
  tableHeight.value = get_heigth();

  window.onresize = () => {
    tableHeight.value = get_heigth();
  };
});

//////////////////////////////////
const activeName = ref("list");

const handleClick = (tab, event) => {
  console.log(tab.paneName, event, activeName.value);
  emit("tabsActiveNameChange", tab.paneName);
};
watch(
  () => props.tabsName,
  (val) => {
    console.log("watch", val);
    activeName.value = val;
  }
);
</script>

<style lang="scss" scoped>
::v-deep(.el-table .cell .gps-span) {
  // display: inline-block;
  margin-right: 5px;
}
.gps-box {
  box-sizing: border-box;
  padding: 0px;
  .gps-search {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    margin-top: 10px;
    //border:1px solid #ccc;
    .gps-item {
      box-sizing: border-box;
      padding-right: 10px;
      line-height: 35px;
    }
  }
}
::v-deep .el-tabs {
  //border:1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  border-top: none;
}
::v-deep .el-tabs__nav-wrap {
  //margin:10px
}
::v-deep .el-table--fit {
  background: #fafafa;
  border: 1px solid #eee;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
::v-deep .el-tabs__item {
  background: #eee;
  color: #000 !important;
  border-radius: 30px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #e0e6f1;
  margin-right: 10px;
  margin-top: 5px;
}
::v-deep .el-tabs__item.is-active {
  border-radius: 30px;
}
::v-deep .xunze {
  background: #ff5f49 !important;
  color: #fff !important;
}
::v-deep .xunze:hover {
  color: #fff !important;
}
::v-deep .da3 {
  color: rgb(44, 120, 191);
  font-size: 25px;
  cursor: pointer;
}
::v-deep .da3:hover {
  font-size: 29px;
}
::v-deep .cc1 table tr td:nth-child(2) div {
  background: #fdc000;
  display: inline-block;
  margin-top: 2px;
  padding: 2px 10px;
  border-radius: 5px;
  color: #000;
  position: relative;
}
::v-deep .cc1 table tr td:nth-child(2) div span:before {
  position: absolute;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  content: "";
  display: block;
  border: 1px solid currentColor;
  border-radius: 4px;
}
.cc2 {
  border-radius: 5px;
  height: 27px;
}
::v-deep .cc3 table tr td:nth-child(1) div {
  background: #fdc000;
  display: inline-block;
  margin-top: 2px;
  margin-left: 5px;
  padding: 2px 10px;
  border-radius: 5px;
  color: #000;
  position: relative;
}
::v-deep .cc3 table tr td:nth-child(1) div span:before {
  position: absolute;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  content: "";
  display: block;
  border: 1px solid currentColor;
  border-radius: 4px;
}
::v-deep .el-table .cell span {
  font-weight: normal !important;
}
</style>
<style>
.el-select-dropdown__wrap {
  max-height: 550px !important;
}
</style>
